<template>
  <div id="app">
    <transition name="slide">
      <router-view />
    </transition>
    <!-- <f /> -->
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// import f from "@/components/base/footer";
export default {
  // components: {
  //   f
  // },
  computed: {
    ...mapState({
      toast: state => state.global.toast
    })
  },
  watch: {
    toast(txt) {
      if (txt) {
        this.showToast(txt);
      }
    }
  },
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://s4.cnzz.com/z_stat.php?id=1279230407&web_id=1279230407";
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  methods: {
    ...mapMutations({
      setGlobalToast: "global/SET_TOAST"
    }),
    showToast(txt) {
      const toast = this.$createToast({
        type: "warn",
        time: 1500,
        txt,
        onTimeout: () => {
          this.setGlobalToast();
        }
      });
      toast.show();
    }
  }
};
</script>
<style lang="stylus" scoped>
#app
  position relative
  width 100%
  height 100%
</style>
