import axios from "@/axios";
/**
 * 获取直播通道
 */
export function getLiveChannel() {
  const url = `live_channel/list.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取直播预告列表
 */
export function getForeshow() {
  const url = `live/advance/list.json`;
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 获取首页赛事预告
 */
export function getMatchPreview() {
  const url = `common/sportsAnnouncementList.json`;
  return axios
    .post(url, {
      clientType: 2
    })
    .then(res => {
      return Promise.resolve(res);
    });
}
/**
 * 主播展示配置
 */
export function getPickConfig() {
  const url = `pickConfig.json`;
  return axios.get(url).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 主播展示列表
 */
export function getAnchorShowList(params) {
  const url = `anchor_list.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
/**
 * 用户pick主播
 */
export function userPickAnchor(params) {
  const url = `anchorPick.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
