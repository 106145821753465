import axios from "@/axios";
export function getCountryList() {
  const url = "common/countryCodeList.json";
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
export function getProtocol() {
  const url = "common/article.json";
  const params = {
    id: 2
  };
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function loginPassword(params) {
  const url = "auth/loginPassword.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getUserInfo() {
  const url = "live_user/view.json";
  return axios.post(url).then(res => {
    return Promise.resolve(res);
  });
}
export function loginCode(params) {
  const url = "auth/login.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getCodeMsg(type, mobile, code) {
  const url = "common/msg.json";
  const params = {
    type: type,
    mobile: mobile,
    countryCode: code
  };
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function resetPassword(params) {
  const url = "auth/resetPassword.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function registerForPhone(params) {
  const url = "auth/registerForPhone.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function update(params) {
  const url = "live_user/update.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function msg(params) {
  const url = "user_message/list.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getJoinGuessList(params) {
  const url = "live/guess/liveUserGuessHistory.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getHistoryList(params) {
  const url = "live_video/queryLiveVideoWatchHistoryList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function delHistory(params) {
  const url = "live_video/deleteLiveVideoWatchHistory.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function feedback(params) {
  const url = "common/feedback.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function buyVideoList(params) {
  const url = "live_video/liveVideoPaymentList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function conact(params) {
  const url = "common/kefu.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function article(params) {
  const url = "common/article.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function send(params) {
  const url = "live/liveChat/submit.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getGuestConfig(params) {
  const url = `common/invtation/userInfo.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getGuestList(params) {
  const url = `common/invtation/list.json`;
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
