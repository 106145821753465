<template>
  <div v-if="showFlag" class="clock-in-tips">
    <span class="icon-bg" />
    <clock-in-icon />
    <img src="./img/arrow.png" class="arrow" alt="箭头" />
    <div class="content">
      <p class="text">点击签到领取豪礼</p>
      <p class="text">参加打卡瓜分奖金</p>
      <button @click="setReadRuleTags">知道了</button>
    </div>
  </div>
</template>
<script>
import clockInIcon from "@/components/clock-in/icon";
import { READ_CLOCKIN_TAGS } from "@/api/config";
export default {
  components: {
    clockInIcon
  },
  data() {
    return {
      showFlag: false
    };
  },
  mounted() {
    const isReadClockInRule = localStorage.getItem(READ_CLOCKIN_TAGS);
    if (!isReadClockInRule) {
      this.show();
    }
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    },
    setReadRuleTags() {
      localStorage.setItem(READ_CLOCKIN_TAGS, 1);
      this.hide();
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-tips
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background rgba(0, 0, 0, .5)
  z-index 10
  .icon-bg
    position absolute
    right 14px
    top 3px
    display inline-block
    width 28px
    height 30px
    background #2363CF
  .arrow
    position absolute
    top 50px
    right 68px
    width 50px
  .content
    margin-top 72px
    text-align center
    .text
      line-height 21px
      font-size 13px
      color #fff
    button
      width 94px
      height 26px
      margin-top 20px
      padding 0
      background linear-gradient(180deg, #1A63DB, #2A9AFA)
      box-shadow 0px 1px 9px 0px rgba(6, 6, 6, 0.22)
      border-radius 16px
      border none
      color #fff
</style>
