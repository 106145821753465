<template>
  <footer class="footer">
    <cube-tab-bar :value="label" @change="changeHandler">
      <cube-tab v-for="item in tabs" :label="item.label" :key="item.label">
        <div class="icon">
          <img
            :src="label == item.label ? item.iconSel : item.icon"
            :width="item.width"
          />
        </div>
        <div v-text="item.label" />
      </cube-tab>
    </cube-tab-bar>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sel: "首页",
      tabs: [
        {
          label: "首页",
          path: "/",
          width: 22,
          icon: require("./img/home.png"),
          iconSel: require("./img/home-sel.png")
        },
        {
          label: "视频",
          path: "/video",
          width: 24,
          icon: require("./img/video.png"),
          iconSel: require("./img/video-sel.png")
        },
        {
          label: "App",
          path: "/star",
          width: 18,
          icon: require("./img/down.png"),
          iconSel: require("./img/star-sel.png")
        },
        {
          label: "我的",
          path: "/member",
          width: 22,
          icon: require("./img/member.png"),
          iconSel: require("./img/member-sel.png")
        }
      ]
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  methods: {
    changeHandler(tab) {
      if (tab == "首页" && this.pathname != "/") {
        this.$router.push("/");
      }
      if (tab == "视频" && this.pathname != "/video") {
        this.$router.push("/video");
      }
      if (tab == "App" && this.pathname != "/star") {
        location.href = "http://download.doupai.tv/mobile/index.html";
      }
      if (tab == "我的" && this.pathname != "/member") {
        if (!this.token) {
          this.$router.push("/login");
        } else {
          this.$router.push("/member");
        }
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.footer
  position fixed
  bottom 0
  left 0
  right 0
  padding 3px 0 13px
  background #fff
  z-index 1
  .cube-tab-bar
    .cube-tab
      padding 0
      &.cube-tab_active
        color #1574D3
    .icon
      height 20px
      line-height 20px
      font-size 0
      img
        vertical-align middle
</style>
