import axios from "axios";
import qs from "qs";
import cookie from "js-cookie";
import store from "@/store";
const ERR_OK = "1";
const errorNormal = [
  "user_blacklist",
  "user_friend",
  "liveAddDpp",
  "userGivingGifts",
  "seeLiveUserVoteResult",
  "version",
  "liveUserGuess",
  "liveUserBet",
  "isPhone"
];
const http = axios.create({
  baseURL: `${process.env.VUE_APP_API}:8088/admin/api`
});
http.interceptors.request.use(
  config => {
    const token = cookie.get("token");
    if (token) {
      config.headers.authentication = token;
    }
    config.headers.clientType = 3;
    config.data = qs.stringify(config.data);
    return config;
  },
  err => {
    store.commit("global/SET_TOAST", err);
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  response => {
    const data = response.data;
    if (data.code == ERR_OK || data.code == 200) {
      return data;
    } else {
      const errorIndex = errorNormal.findIndex(item => {
        return response.config.url.indexOf(item) > -1;
      });
      if (errorIndex > -1) {
        return data;
      }
      store.commit("global/SET_TOAST", data.message);
    }
  },
  err => {
    return Promise.reject(err);
  }
);
export default http;
