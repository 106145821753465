const state = {
  roomInfo: {},
  roomBarrageList: [],
  roomReady: 0,
  roomGiftsMsg: {},
  deleteMsg: {},
  lotteryInfo: {},
  joinLotteryHistory: localStorage.getItem("__lottery__") || 0,
  chatText: "",
  voteInfo: {}
};
const types = {
  SET_ROOM_INFO: "SET_ROOM_INFO",
  SET_ROOM_BARRAGE_LIST: "SET_ROOM_BARRAGE_LIST",
  ADD_ROOM_BARRAGE_LIST: "ADD_ROOM_BARRAGE_LIST",
  DEL_ROOM_BARRAGE_LIST: "DEL_ROOM_BARRAGE_LIST",
  ROOM_READY: "ROOM_READY",
  ROOM_GIFTS_MSG: "ROOM_GIFTS_MSG",
  ROOM_DELETE_MSG: "ROOM_DELETE_MSG",
  SET_LOTTERY_INFO: "SET_LOTTERY_INFO",
  SET_JOIN_LOTTERY_HISTORY: "SET_JOIN_LOTTERY_HISTORY",
  SET_CHAT_TEXT: "SET_CHAT_TEXT",
  SET_VOTE_INFO: "SET_VOTE_INFO"
};
const mutations = {
  [types.SET_ROOM_INFO](state, info) {
    state.roomInfo = info;
  },
  [types.SET_ROOM_BARRAGE_LIST](state, list = []) {
    state.roomBarrageList = list;
  },
  [types.ADD_ROOM_BARRAGE_LIST](state, msg) {
    state.roomBarrageList.push(msg);
  },
  [types.DEL_ROOM_BARRAGE_LIST](state, inx) {
    state.roomBarrageList.splice(inx, 1);
  },
  [types.ROOM_READY](state, mode = 0) {
    state.roomReady = mode;
  },
  [types.ROOM_GIFTS_MSG](state, msg) {
    state.roomGiftsMsg = msg;
  },
  [types.ROOM_DELETE_MSG](state, msg) {
    state.deleteMsg = msg;
  },
  [types.SET_LOTTERY_INFO](state, info) {
    state.lotteryInfo = info;
  },
  [types.SET_JOIN_LOTTERY_HISTORY](state, id) {
    localStorage.setItem("__lottery__", id);
    state.joinLotteryHistory = id;
  },
  [types.SET_CHAT_TEXT](state, text) {
    state.chatText = text;
  },
  [types.SET_VOTE_INFO](state, info) {
    state.voteInfo = info;
  }
};
export default { namespaced: true, state, mutations };
