import { getCountryList, getUserInfo } from "@/api/user";
import cookie from "js-cookie";

const state = {
  token: "",
  info: {},
  countryList: []
};
const types = {
  SET_TOKEN: "SET_TOKEN",
  SET_INFO: "SET_INFO",
  SET_COUNTRY_LIST: "SET_COUNTRY_LIST"
};
const mutations = {
  [types.SET_TOKEN](state, token) {
    state.token = token;
  },
  [types.SET_INFO](state, info = {}) {
    state.info = info;
  },
  [types.SET_COUNTRY_LIST](state, list) {
    state.countryList = list;
  }
};
const actions = {
  _getCountList({ commit }) {
    getCountryList().then(res => {
      commit("SET_COUNTRY_LIST", res.object);
    });
  },
  _getUserInfo({ commit }) {
    getUserInfo().then(res => {
      if (!res) return;
      const options = { expires: 365 };
      let data = res.object;
      if (data.sex === 0) {
        data.sex = 3;
      }
      data.avatar = data.avatar
        ? data.avatar
        : require("@/assets/img/logo.png");
      data = JSON.stringify(res.object);
      cookie.set("user", data, options);
      commit("SET_INFO", res.object);
    });
  },
  clear({ commit }) {
    cookie.remove("token");
    cookie.remove("user");
    commit("SET_TOKEN", "");
    commit("SET_INFO", {});
  }
};
export default { namespaced: true, state, mutations, actions };
