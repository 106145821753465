import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import cookie from "js-cookie";
import Home from "../views/home/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search/index")
  },
  {
    path: "/video",
    name: "Video",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/video/index")
  },
  {
    path: "/more",
    name: "More",
    component: () =>
      import(/* webpackChunkName: "videomore" */ "../views/video/more")
  },
  {
    path: "/video/:id",
    component: () =>
      import(/* webpackChunkName: "videodetails" */ "../views/video/id")
  },
  {
    path: "/star",
    name: "Star",
    component: () => import(/* webpackChunkName: "star" */ "../views/star")
  },
  {
    path: "/clock-in",
    name: "Clock-in",
    component: () =>
      import(/* webpackChunkName: "clock-in" */ "../views/clock-in")
  },
  {
    path: "/clock-logs",
    name: "Clock-logs",
    component: () =>
      import(/* webpackChunkName: "clock-logs" */ "../views/clock-in/logs")
  },
  {
    path: "/member",
    name: "Member",
    component: () => import(/* webpackChunkName: "member" */ "../views/member"),
    children: [
      {
        path: "setting",
        component: () =>
          import(/* webpackChunkName: "setting" */ "../views/member/setting")
      },
      {
        path: "msg",
        component: () =>
          import(/* webpackChunkName: "msg" */ "../views/member/msg")
      },
      {
        path: "level",
        component: () =>
          import(/* webpackChunkName: "level" */ "../views/member/level")
      },
      {
        path: "vip",
        component: () =>
          import(/* webpackChunkName: "vip" */ "../views/member/vip")
      },
      {
        path: "wallet",
        component: () =>
          import(/* webpackChunkName: "wallet" */ "../views/member/wallet")
      },
      {
        path: "dpp",
        component: () =>
          import(/* webpackChunkName: "dpp" */ "../views/member/dpp")
      },
      {
        path: "guess",
        component: () =>
          import(/* webpackChunkName: "guess" */ "../views/member/guess")
      },
      {
        path: "history",
        component: () =>
          import(/* webpackChunkName: "history" */ "../views/member/history")
      },
      {
        path: "payvideo",
        component: () =>
          import(/* webpackChunkName: "payvideo" */ "../views/member/payvideo")
      },
      {
        path: "guest",
        component: () =>
          import(/* webpackChunkName: "guest" */ "../views/member/guest")
      },
      {
        path: "feedback",
        component: () =>
          import(/* webpackChunkName: "feedback" */ "../views/member/feedback")
      },
      {
        path: "service",
        component: () =>
          import(/* webpackChunkName: "service" */ "../views/member/service")
      },
      {
        path: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/member/about")
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login")
  },
  {
    path: "/:id",
    name: "room",
    component: () =>
      import(/* webpackChunkName: "room" */ "../views/room/index")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

router.beforeResolve((to, from, next) => {
  const token = cookie.get("token");
  const user = cookie.get("user") ? JSON.parse(cookie.get("user")) : {};
  store.commit("user/SET_TOKEN", token);
  store.commit("user/SET_INFO", user);
  if (token) {
    store.dispatch("user/_getUserInfo");
  }
  next();
});

export default router;
