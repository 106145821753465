<template>
  <span class="clock-in-icon" @click="toClockIn" />
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    toClockIn() {
      if (!this.token) {
        this.toast("请先登录");
        return;
      }
      this.$router.push("/clock-in");
    }
  }
};
</script>
<style lang="stylus" scoped>
.clock-in-icon
  position absolute
  top 11px
  right 20px
  display inline-block
  width 14px
  height 15px
  background url(./img/clock.png) no-repeat center center
  background-size 100%
  z-index 1
</style>
