<template>
  <header class="header">
    <slot />
    <button v-if="isback" class="back" @click="back">
      <i class="cubeic-back" />
    </button>
    <button v-if="iscancel" class="back" @click="cancel">
      <i class="cubeic-back" />
    </button>
  </header>
</template>
<script>
export default {
  props: {
    isback: {
      type: Boolean,
      default: false
    },
    iscancel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
<style lang="stylus" scoped>
.header
  position relative
  height 36px
  line-height 36px
  background #2364CF
  font-size 0
  text-align center
  overflow hidden
  z-index 10
  .search-box
    position relative
    width 222px
    height 24px
    margin 6px auto 0
    padding 0 0 0 32px
    background #3776D5
    border-radius 12px
    .icon
      position absolute
      left 8px
      top 3px
    input
      display block
      width 100%
      height 100%
      background-color transparent
      font-size 12px
      color rgba(255, 255, 255, .8)
      &::-webkit-input-placeholder
        color rgba(255, 255, 255, .8)
    .cube-input
      width 100%
      height 100%
      background-color transparent
      &:after
        display none
  .name
    display inline-block
    width 65%
    font-size 17px
    font-weight bold
    color #fff
    word-wrap normal
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  .back
    position absolute
    top 10px
    left 9px
    background none
    border none
    color #fff
  .question
    position absolute
    display inline-block
    width 19px
    height 19px
    line-height 19px
    border 1px solid #fff
    border-radius 50%
    top 9px
    right 9px
    font-size 14px
    text-align center
    color #fff
  .details
    position absolute
    display inline-block
    top 0
    right 9px
    font-size 14px
    text-align center
    color #fff
  .app
    position absolute
    display inline-block
    top 0
    right 20px
    font-size 12px
    text-align center
    color #fff
</style>
