import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "./axios";
/* eslint-disable no-unused-vars */
import {
  Style,
  Toast,
  Scroll,
  Input,
  Textarea,
  TabBar,
  Slide,
  Button,
  Popup,
  Dialog,
  Form,
  Select,
  Picker,
  Checkbox,
  Radio,
  ActionSheet,
  DatePicker,
  CascadePicker,
  ImagePreview
} from "cube-ui";
import "@/assets/stylus/index.styl";
import VueLazyload from "vue-lazyload";
import "./registerServiceWorker";
import fastclick from "fastclick";
import xss from "xss";
/* eslint-disable no-unused-vars */
// import vConsole from 'vconsole'

fastclick.attach(document.body);
Vue.prototype.$axios = axios;
Vue.prototype.$xss = xss;
Vue.use(Toast);
Vue.use(Scroll);
Vue.use(Input);
Vue.use(Textarea);
Vue.use(TabBar);
Vue.use(Slide);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Select);
Vue.use(Picker);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(ActionSheet);
Vue.use(DatePicker);
Vue.use(CascadePicker);
Vue.use(ImagePreview);
Vue.use(VueLazyload, {
  error: require("./assets/img/lazy-loading.png"),
  loading: require("./assets/img/lazy-loading.png")
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
