<template>
  <div class="anchor-show">
    <div class="switch-box">
      <div class="item" :class="{ active: type == 1 }" @click="changeType(1)">
        周榜
      </div>
      <div class="item" :class="{ active: type == 2 }" @click="changeType(2)">
        月榜
      </div>
    </div>
    <ul class="anchor-list">
      <li
        v-for="(anchor, inx) in list"
        :key="anchor.id"
        class="item border-bottom-1px"
      >
        <div v-show="inx < showListLength" class="inner-box">
          <div class="avatar">
            <img class="image" :src="anchor.image" alt="主播头像" />
            <span class="rank">
              <b>NO.{{ inx + 1 }}</b>
            </span>
          </div>
          <div class="text">
            <p class="name">{{ anchor.anchorName }}</p>
            <p class="desc">{{ anchor.remark }}</p>
          </div>
          <div class="r">
            <div class="pick-value">
              <img src="./img/pick-value.png" alt="pick值" />
              <span class="pick-num">{{ anchor.pick }}</span>
            </div>
            <div
              class="pick-btn"
              :class="{ lock: anchor.flag == 2 }"
              @click="_userPickAnchor($event, anchor)"
            >
              PICK
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div
      v-show="list.length != showListLength"
      class="more"
      @click="showListLength = list.length"
    >
      <img src="./img/more.png" alt="更多" />
      <span>展开查看更多主播</span>
    </div>
  </div>
</template>
<script>
import Velocity from "velocity-animate";
import { getAnchorShowList, userPickAnchor } from "@/api/index";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      type: 1,
      list: [],
      showListLength: 3
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info
    })
  },
  mounted() {
    this._getAnchorShowList();
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    changeType(type) {
      this.type = type;
      this._getAnchorShowList();
    },
    async _getAnchorShowList() {
      const params = {
        type: this.type
      };
      const res = await getAnchorShowList(params);
      this.pickLoading = false;
      if (!res) return;
      this.list = res.object;
    },
    _userPickAnchor(ev, anchor) {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      if (!this.user.dppIntegral) {
        this.toast("斗币不足，无法为主播PICK");
        return;
      }
      if (this.pickLoading) {
        return;
      }
      this.pickLoading = true;
      const ele = ev.target;
      const ref = ele.parentElement;
      const dom = ref.querySelector(".pick-num");
      let animationNum = document.createElement("i");
      animationNum.style.position = "absolute";
      animationNum.style.right = "0";
      animationNum.style.fontSize = "10px";
      animationNum.style.fontStyle = "normal";
      animationNum.innerText = `+1`;
      dom.appendChild(animationNum);
      Velocity(
        animationNum,
        { opacity: 0, top: "-20px" },
        {
          complete: () => {
            const params = {
              anchorId: anchor.id
            };
            userPickAnchor(params).then(res => {
              if (!res) return;
              this._getAnchorShowList();
            });
          }
        }
      );
    }
  }
};
</script>
<style lang="stylus" scoped>
.anchor-show
  position relative
  .switch-box
    display flex
    justify-content center
    align-items center
    width 196px
    height 25px
    line-height 25px
    background linear-gradient(-55deg, #1B64DC, #2A99FA)
    margin 0 auto 12px
    transform skew(-10deg)
    .item
      flex 0 0 49%
      height 23px
      text-align center
      color #fff
      &.active
        background #fff
        color #1278D4
  .anchor-list
    border-top-left-radius 3px
    border-top-right-radius 3px
    background #fff
    .item
      &:nth-of-type(1)
        .inner-box
          .avatar
            .rank
              background-image url(./img/top1.png)
      &:nth-of-type(2)
        .inner-box
          .avatar
            .rank
              background-image url(./img/top2.png)
      &:nth-of-type(3)
        .inner-box
          .avatar
            .rank
              background-image url(./img/top3.png)
      .inner-box
        display flex
        align-items center
        padding 12px 8px
        .avatar
          position relative
          flex 0 0 44px
          font-size 0
          img
            width 44px
            height 44px
          .rank
            position absolute
            left 0
            bottom 0
            display inline-block
            width 27px
            height 13px
            line-height 13px
            background url(./img/top4.png) no-repeat center center
            background-size 100%
            color #6B6B6B
            b
              display inline-block
              font-size 10px
              transform scale(.6)
        .text
          flex 1
          margin-left 6px
          .name
            font-size 11px
            font-weight bold
            color #2280EB
          .desc
            font-size 10px
            color #666
        .r
          flex 0 0 73px
          margin-left 5px
          .pick-value
            margin-bottom 5px
            font-size 0
            text-align center
            img
              width 13px
              vertical-align middle
              margin-right 4px
            span
              position relative
              display inline-block
              vertical-align middle
              font-size 12px
              font-weight bold
              color #FA4D49
          .pick-btn
            width 73px
            height 20px
            line-height 20px
            background url(./img/pick-light.png) no-repeat center center
            background-size 100%
            text-align center
            font-size 12px
            color #fff
            &.lock
              background-image url(./img/pick-gray.png)
  .more
    padding 7px 0
    background #fff
    font-size 0
    text-align center
    img
      width 7px
      margin-right 3px
    span
      font-size 10px
      color #666
</style>
