<template>
  <div class="home">
    <h>
      <div class="search-box">
        <img src="./img/search.png" width="16" class="icon" />
        <cube-input placeholder="搜索视频" @focus="toSearch" />
      </div>
      <!-- <a class="app" href="http://download.doupai.tv/mobile/index.html">
        下载App
      </a> -->
      <clock-in-icon />
    </h>
    <section class="scroll-wrap">
      <cube-scroll ref="scroll" :options="{ click: true, bounce: false }">
        <div class="recommend" @click="goRoom(activeChannel.roomId)">
          <img
            v-lazy="
              `http://pic-ws.doupai.live/doupai/${activeChannel.pushUrl}.jpg`
            "
            class="cover"
          />
          <div class="mask">
            <p class="desc">
              <span class="l">
                <img src="./img/channel1.png" width="13" />
                {{ activeChannel.name }}
                &nbsp;
                <img src="./img/fire.png" width="12" />
                {{ activeChannel.count }}
              </span>
              <span class="r ellips" v-text="activeChannel.liveTopical" />
            </p>
          </div>
        </div>
        <ul class="channel">
          <li
            v-for="(item, index) in channel"
            :key="item.id"
            class="item"
            @click="goRoom(item.roomId)"
          >
            <div class="title">
              <img :src="channelIcons[index]" width="18" />
              {{ item.name }}
            </div>
            <div class="inner">
              <div class="preview">
                <img
                  v-lazy="
                    `http://pic-ws.doupai.live/doupai/${item.pushUrl}.jpg`
                  "
                />
              </div>
              <div class="desc ellips" v-text="item.liveTopical" />
            </div>
          </li>
        </ul>
        <div class="preview-list">
          <h3 class="title" @click="_getPickConfig">
            <img src="./img/channel5.png" width="19" />
            主播展示
            <span class="question">?</span>
          </h3>
          <anchor-show />
          <!-- <div ref="preview">
            <cube-scroll
              ref="scroll"
              direction="horizontal"
              :data="previewList"
              :options="{ eventPassthrough: 'vertical' }"
              class="horizontal-scroll-list-wrap"
            >
              <ul ref="list" class="list">
                <li v-for="item in previewList" :key="item.id" class="item">
                  <div class="avatar">
                    <img :src="resetUrl(item.anchorImage)" width="59" />
                  </div>
                  <div class="name" v-text="item.anchorNickName" />
                  <div class="desc ellips" v-text="item.advanceDesc" />
                  <div class="time" v-text="item.advanceTime.split(' ')[0]" />
                </li>
              </ul>
            </cube-scroll>
          </div> -->
        </div>
        <div class="match-preview">
          <h3 class="title">
            <img src="./img/channel4.png" width="20" />
            赛事预告
          </h3>
          <div class="inner">
            <div class="cover">
              <img :src="resetUrl(matchPreview.imageUrl)" />
            </div>
            <div class="text">
              <h2>
                <b v-text="matchPreview.title" />
                <span v-text="matchPreview.forecastTime" />
              </h2>
              <div class="desc" v-text="matchPreview.description" />
            </div>
          </div>
        </div>
      </cube-scroll>
    </section>
    <f label="首页" />
    <clock-in-tips />
  </div>
</template>
<script>
import h from "@/components/base/header";
import f from "@/components/base/footer";
import clockInIcon from "@/components/clock-in/icon";
import clockInTips from "@/components/clock-in/tips";
import anchorShow from "@/components/home/anchor-show/anchor-show";
import { getLiveChannel, getMatchPreview, getPickConfig } from "@/api/index";
export default {
  components: {
    h,
    f,
    clockInIcon,
    clockInTips,
    anchorShow
  },
  data() {
    return {
      keyword: "",
      channel: [],
      activeChannel: {},
      channelIcons: [
        require("./img/channel2.png"),
        require("./img/channel3.png")
      ],
      // previewList: [],
      matchPreview: {},
      config: {}
    };
  },
  created() {
    this._getLiveChannel();
    // this._getForeshow();
    // this._getPickConfig();
    this._getMatchPreview();
  },
  methods: {
    _getLiveChannel() {
      getLiveChannel().then(res => {
        const data = res.object;
        this.activeChannel = data.splice(0, 1)[0];
        this.channel = data;
      });
    },
    // _getForeshow() {
    //   getForeshow().then(res => {
    //     const data = res.object;
    //     this.previewList = data;
    //   });
    // },
    _getMatchPreview() {
      getMatchPreview().then(res => {
        this.matchPreview = res.object[0];
      });
    },
    goRoom(id) {
      this.$router.push(`/${id}`);
    },
    toSearch() {
      this.$router.push("/search");
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    },
    async _getPickConfig() {
      if (!this.config.image) {
        const result = await getPickConfig();
        if (!result) return;
        this.config = result.object;
        this.showGuestTips();
      } else {
        this.showGuestTips();
      }
    },
    showGuestTips() {
      this.$createImagePreview({
        imgs: [this.config.image]
      }).show();
    }
  }
};
</script>
<style lang="stylus" scoped>
.home
  position relative
  width 100%
  height 100%
  // padding 0 0 53px
  // background #E9ECED
  .scroll-wrap
    // height calc(100vh - 36px - 53px)
    position fixed
    top 36px
    right 0
    bottom 53px
    left 0
    transform translateZ(0)
  .recommend
    position relative
    width 100%
    height calc(100vw / (16/9))
    .cover
      width 100%
      height 100%
    .mask
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      box-shadow 0px -60px 25px -25px rgba(0,0,0,.5) inset
      .desc
        position absolute
        display flex
        line-height 30px
        margin 0 5px
        left 0
        right 0
        bottom 0
        .l,.r
          position relative
          flex 1
          vertical-align middle
          font-size 14px
          color #fff
          img
            position relative
            top 1px
        .r
          text-align right
  .title
    margin 8px 0
    vertical-align middle
    font-size 17px
    font-weight bold
    .question
      display inline-block
      width 13px
      height 13px
      line-height 13px
      border 1px solid #A6A7A7
      border-radius 50%
      color #A6A7A7
      font-size 10px
      text-align center
  .channel
    display flex
    margin 0 15px
    .item
      width 169px
      margin-right 8px
      margin-bottom 8px
      &:nth-of-type(2n+0)
        margin-right 0
      .inner
        width 100%
        background #fff
        border-radius 3px
        overflow hidden
        .preview
          margin 0
          font-size 0
          img
            width 100%
            height 95px
        .desc
          margin 5px
          font-size 13px
          color #383A40
  .preview-list
    margin 0 14px
    overflow hidden
    .list
      display inline-block
      white-space nowrap
      .item
        position relative
        display inline-block
        width 113px
        padding 53px 6px 0
        background #fff
        margin-top 29px
        margin-right 5px
        border-radius 3px
        text-align center
        .avatar
          position absolute
          top -20px
          left 50%
          margin-left -29.5px
          img
            border 2px solid #fff
            border-radius 50%
        .name
          margin-bottom 15px
          font-size 12px
          color #383A40
        .desc
          margin-bottom 6px
          color #6B6B6B
        .time
          padding 10px 0
          border-top 1px solid #F0F0F0
          font-weight bold
          font-size 15px
          color #000
  .match-preview
    margin 0 14px
    padding-bottom 8px
    .inner
      background #fff
      border-radius 4px
      overflow hidden
      .text
        padding 9px 8px 9px 10px
        overflow hidden
        h2
          color #000
          b
            font-size 14px
            font-weight bold
            margin-right 8px
          span
            font-size 13px
        .desc
          font-size 13px
          color #6B6B6B
</style>
