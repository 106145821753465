const state = () => ({
  toast: ""
});
const types = {
  SET_TOAST: "SET_TOAST"
};
const mutations = {
  [types.SET_TOAST](state, toast = "") {
    state.toast = toast;
  }
};

export default { namespaced: true, state, mutations };
